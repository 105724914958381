<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :showTime="false" languageType="EN"></headers>
    <img src="@/assets/speaial_activity_img/imageEn/time_title.png" class="time-title" alt="">
    <img src="@/assets/speaial_activity_img/imageEn/eleventitle.png" class="eleven-title" alt="">
    <div class="hint">
      Stay tunes for “2023 Double 11 Sales Data Analysis Report” on Nov. 12th.
    </div>
    <div class="main">
      <img src="@/assets/speaial_activity_img/imageEn/disclaimer.png" alt="">
      <div class="text">
        <p>Contact us:</p>
        <p>Tel: 400-066-9026</p>
        <p>Web: www.syntun.com</p>
        <p>E-mail: info@syntun.com</p>
        <p>Facebook: https://www.facebook.com/Syntun.Ltd</p>
        <p>Twitter: https://twitter.com/syntunchina</p>
        <p>LinkedIn: https://www.linkedin.com/company/syntun</p>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'

export default {
  components: {
    Headers,
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/en.scss';
</style>